<template>
  <a-modal
    :visible="visible"
    title="添加到关系组"
    :mask-closable="false"
    :width="600"
    @ok="handleOk"
    centered
    @cancel="handleCancel"
    forceRender
    :destroyOnClose="true"
  >
    <!--  v-model:checked-keys="checkedKeys"  -->
    <!--    <a-tree-->
    <!--      class="iss-tree"-->
    <!--      checkable-->
    <!--      check-strictly-->
    <!--      block-node-->
    <!--      v-model:expanded-keys="expandedKeys"-->
    <!--      :selected-keys="selectedKeys"-->
    <!--      :tree-data="treeData"-->
    <!--      :replace-fields="{ key: 'id' }"-->
    <!--      @check="handleSelect"-->
    <!--    >-->
    <!--      <template #title="{ groupName }">-->
    <!--        <div class="iss-tree-item">-->
    <!--          <span class="flex-1">{{ groupName }}</span>-->
    <!--        </div>-->
    <!--      </template>-->
    <!--    </a-tree>-->
    <div class="tiesGroup">
      <a-button type="primary" @click="handleOpen">新增关系组</a-button>
    </div>
    <div class="iss-pulse">
      <a-collapse
        v-model:activeKey="activeKey"
        :bordered="false"
        accordion
        v-for="item in treeData"
        :key="item.id"
        :expand-icon-position="expandIconPosition"
      >
        <!--        <template #expandIcon style="color: #ff9533">-->
        <!--          <component-->
        <!--              :is="icons[`${item.icon}`]"-->
        <!--              class="activeColor marginRight5"-->
        <!--          ></component>-->
        <!--        </template>-->
        <a-collapse-panel
          :key="item.id"
          :expandIconPosition="left"
          :class="{ showBack: activeKey === item.id }"
        >
          <template #header>
            <div class="headerContent" @click="handleItem(item)">
              <img
                src="@/assets/images/box/frequenct.png"
                alt=""
                class="frequenct-img"
                :class="{ activeClass: item.id === showBut }"
              />
              <div class="groupName">{{ item.groupName }}</div>
              <!--              <div class="size">{{ item.size }}人</div>-->
              <!--              <div class="description">{{ item.description }}</div>-->
            </div>
          </template>

          <div
            v-for="i in item.children"
            :key="i.key"
            @click="handleItem(i)"
            class="panel"
          >
            <img
              src="@/assets/images/box/frequenct.png"
              alt=""
              class="frequenct-imgTwo"
              :class="{ activeClass: i.id === showBut }"
            />
            {{ i.groupName }}
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>

    <template v-slot:footer>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleOk" :loading="loading"
        >确认</a-button
      >
    </template>
  </a-modal>
  <add-ties-group
    v-model:visible="openTiesGroup"
    :activeItem="activeItem"
    @fnOk="handleAddPage"
  />
</template>
<script>
import { reactive, toRaw, toRefs, watch, ref, onMounted } from 'vue';
import { message, Collapse } from 'ant-design-vue';
import { useStore } from 'vuex';
import contactAll from '@/api/contactAll';
import * as icons from '@ant-design/icons-vue';
import addTiesGroup from '@/views/contacts/contactAall/addTiesGroup';

// import { validMobile, validEmail } from '@/utils/validation';
// import {
//   PlusOutlined,
// } from '@ant-design/icons-vue';

export default {
  components: {
    // ATree: Tree,
    ACollapse: Collapse,
    ACollapsePanel: Collapse.Panel,
    addTiesGroup,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const store = useStore();
    const expandIconPosition = ref('right');
    const userId = store.state.account.user.id;
    const form = reactive({
      contactId: [],
      userId: userId,
      groupId: '',
      selectAll: null,
      pageDTO: {},
    });

    const state = reactive({
      treeData: [],
      selectedKeys: [],
      loading: false,
      activeKey: '',
      showBut: '',
      openTiesGroup: false,
    });

    watch(
      () => props.init,
      value => {
        console.log('添加到关系组', value)
        Object.assign(form.contactId, value.id);
        form.selectAll = value.selectAll
        form.pageDTO = value.pageDTO

      }
    );

    const getData = () => {
      contactAll.getContactGroupsList('', userId).then(res => {
        Object.assign(state.treeData, res);
      });
    };
    onMounted(() => {
      getData();
    });

    const handleCancel = () => {
      context.emit('update:visible', false);
    };

    return {
      ...toRefs(state),
      form,
      icons,
      expandIconPosition,
      // validateInfos,
      handleCancel,
      handleOpen() {
        state.openTiesGroup = true;
        // context.emit('fnOk', false);
      },
      // 分类列表保存方法调用接口的方法和事件
      handleOk: () => {
        if (form.groupId === '') {
          return message.info('请选择关系组');
        } else {
          state.loading = true;

          contactAll.saveContact2Group('', toRaw(form)).then(() => {
            state.loading = false;
            message.success('操作成功');
            context.emit('fnOk', false);
            state.activeKey = '';
            state.showBut = '';
          }).catch(() => {
            message.error('网络异常，请联系技术人员处理')
            state.loading = false;
          });
        }
      },

      // handleSelect: checkedKeys => {
      //   console.log('item', checkedKeys, checkedKeys.checked);
      //   // state.selectedKeys = [].concat(checkedKeys.checked[checkedKeys.checked.length - 1]);
      //   form.groupId = checkedKeys.checked[0];
      // },

      handleItem(i) {
        state.showBut = i.id;
        form.groupId = i.id;
      },
      handleAddPage(value) {
        state.openTiesGroup = value;
        getData();
      },
    };
  },
};
</script>
<style lang="less" scoped>
.iss-tree {
  max-height: 500px;
  overflow: auto;
}
.listClass {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #eeeeee;
  padding: 13px 44px;
  align-items: center;
  cursor: pointer;
}
.frequenct-img {
  //margin-right: 12px;
  display: none;
  width: 13px;
  height: 13px;
  //position: absolute;
  //left: 0px;
  //top: 18px;
  margin-right: 6px;
}
.frequenct-imgTwo {
  display: none;
  width: 13px;
  height: 13px;
  //position: absolute;
  //left: 53px;
  margin-right: 6px;
}
.activeClass {
  display: block;
}
.activeBackgroud {
  background: rgba(255, 123, 0, 0.08);
}
.iss-pulse {
  max-height: calc(100vh - 388px);
  overflow-y: auto;

  .panel {
    //margin-left: 24px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 12px;
    border-bottom: 0.5px solid #eeeeee;
  }

  :deep(.ant-collapse-borderless) {
    background: #ffffff;
  }
  :deep(.ant-collapse-header) {
    border-bottom: 1px solid #eeeeee;
    border-right: none;
    border-left: none;
  }
  :deep(.ant-collapse-content > .ant-collapse-content-box) {
    padding-bottom: 5px;
  }

  .headerContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .groupName {
      width: 160px;
      white-space: nowrap; /*超出的空白区域不换行*/
      overflow: hidden; /*超出隐藏*/
      text-overflow: ellipsis; /*文本超出显示省略号*/
    }
    .size {
      margin: 0px 10px;
      width: 80px;
    }
    .description {
      width: 200px;
      white-space: nowrap; /*超出的空白区域不换行*/
      overflow: hidden; /*超出隐藏*/
      text-overflow: ellipsis; /*文本超出显示省略号*/
    }
  }
}
.iss-pulse {
  margin: 20px;
}

.iss-pulse /deep/ .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none;
}
.iss-pulse /deep/ .ant-collapse > .ant-collapse-item {
  border-bottom: none;
}

//.headeIcon {
//  color: #FF9533 !important;
//}
.iss-pulse
  /deep/
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  color: #ff9533 !important;
}
.tiesGroup {
  text-align: right;
  margin-bottom: 16px;
}
</style>
